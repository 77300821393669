$brand: #034d28;
$hero-bg: rgba($brand, 0.9);
$footer-bg: #404442;

.brand-color {
	color: $brand;
}

.btn-xl {
	padding: 1rem 2rem !important;
}

.navbar--ds {
	background: $hero-bg;

	a.nav-link--ds {
		color: white;
		text-transform: uppercase;

		&:hover,
		&:active,
		&.active {
			color: $primary !important;
		}

		+ .nav-item {
			.dropdown-toggle {
				color: white;
				text-transform: uppercase;

				&:hover,
				&.show {
					color: $primary !important;
				}
			}
		}
	}
}

.home-video-out {
	position: relative;
	background: $hero-bg;

	.home-video-container {
		bottom: 0;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: -107px;
		z-index: -1;

		&:before {
			content: '';
			background-image: url('../../public/assets/pattern2.png');
			background-repeat: repeat;
			background-position: center;
			background-size: 25%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
		}

		.home-video-in {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			height: auto;
			width: auto;
			min-width: 100%;
			min-height: 100%;
		}
	}
}

.hero--ds {
	border-top: 3px solid rgba(255, 255, 255, 0.3);
	padding: 5rem 3rem;
	color: white;
	text-align: center;

	.hero-line {
		color: lighten($primary, 10%);
	}
}

.hero-sep--ds {
	background-image: url('../../public/assets/hero-sep.png');
	min-height: 170px;
	position: relative;
	margin-top: -100px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	&.hero-sep--ds-gr {
		background-image: url('../../public/assets/hero-sep-gr.png');
	}

	svg {
		fill: white;
	}
}

.hero--ds-info {
	position: relative;
	padding-bottom: 5rem;
	font-size: 20px;
	color: white;
	padding-left: 2rem;
	padding-right: 2rem;
}

.home-screen-2 {
	background-image: url('../../public/assets/main_bg_02.png');
	background-position: 40% 50px;
	background-repeat: no-repeat;

	&-h2 {
		text-transform: uppercase;
		font-size: 42px;
		font-weight: 600;
		margin-bottom: 3rem;
	}

	&-p {
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 3rem;
	}

	&-embed {
		display: flex;

		iframe {
			margin: 0 auto;
			border-radius: 10px;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
		}
	}
}

.home-screen-count-line {
	background-color: $brand;
	padding: 7rem 0 4rem;
	margin-top: -50px;
	color: white;
	text-align: center;

	h3 {
		font-size: 42px;
		margin-bottom: 1rem;
	}

	p {
		font-size: 24px;
		font-weight: 300;
		line-height: 1.4;
		padding-right: 3rem;
		padding-left: 3rem;
	}
}

.home-block {
	padding: 7em 0;

	.home-screen-2-h2 {
		padding: 1em 0;
	}

	.service-block {
		min-height: 567px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
		padding: 2rem 2rem;
		display: flex;
		flex-direction: column;
		margin-bottom: 1.5rem;
		border-radius: 15px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		&.service-block-1 {
			background-image: url('../../public/assets/ds-serv-1b.jpg');
		}

		&.service-block-2 {
			background-image: url('../../public/assets/ds-serv-2b.jpg');
		}

		&.service-block-3 {
			background-image: url('../../public/assets/ds-serv-3b.jpg');
		}

		&.service-block-4 {
			background-image: url('../../public/assets/ds-serv-4b.jpg');
		}

		&:before {
			content: '';
			border-radius: 15px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 1));
		}

		&.service-block-1 {
			&:before {
				background-image: none;
			}
		}

		&-icon {
			flex-grow: 1;
			margin-left: -2.5rem;
			margin-top: -1rem;
		}

		h3 {
			margin-bottom: 2rem;
			font-size: 38px;
			font-weight: 600;
			z-index: 1;

			a {
				color: white;
				text-decoration: none;
				transition: color 0.1s ease-in-out;

				&:hover {
					color: $primary;
				}
			}
		}

		&-btn {
			z-index: 1;
			cursor: pointer;
		}
	}
}

.service-block-icon {
	position: relative;

	&:before {
		content: '';
		width: 217px;
		height: 217px;
		position: absolute;
		background-size: cover;
		border-radius: 10px;

		.service-block-1 & {
			background-image: url('../../public/assets/ds-serv-icon-1.png');
		}

		.service-block-2 & {
			background-image: url('../../public/assets/ds-serv-icon-2.png');
		}

		.service-block-3 & {
			background-image: url('../../public/assets/ds-serv-icon-3.png');
		}

		.service-block-4 & {
			background-image: url('../../public/assets/ds-serv-icon-4.png');
		}
	}
}

.home-brand {
	background-color: $brand;
}

.home-cta {
	padding-top: 1rem;
	padding-bottom: 4rem;
	text-align: center;

	&-title {
		color: white;
		margin-bottom: 2rem;
		padding-top: 0.2rem;
		@include media-breakpoint-up(lg) {
			text-align: right;
			margin-bottom: 0;
		}

		strong {
			font-size: 36px;
		}
	}

	&-btn {
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}
}

.accordion-item {
	&:first-child {
		.accordion-header {
			border-top: 2px solid rgba($brand, 40%);
		}
	}

	.accordion-header {
		border-bottom: 2px solid rgba($brand, 40%);

		h5 {
			margin-bottom: 0;
		}

		.accordion-button {
			&:not(.collapsed) {
				&::before {
					background-image: var(--#{$prefix}accordion-btn-active-icon);
					transform: var(--#{$prefix}accordion-btn-icon-transform);
				}
			}

			// Accordion icon
			&::after {
				content: none;
			}

			&::before {
				flex-shrink: 0;
				width: var(--#{$prefix}accordion-btn-icon-width);
				height: var(--#{$prefix}accordion-btn-icon-width);
				margin-right: 1rem;
				content: '';
				background-image: var(--#{$prefix}accordion-btn-icon);
				background-repeat: no-repeat;
				background-size: var(--#{$prefix}accordion-btn-icon-width);
				@include transition(var(--#{$prefix}accordion-btn-icon-transition));
			}
		}
	}
}

.home-faq {
	background-image: url('../../public/assets/main_bg_02.png');

	.accordion {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}

.footer {
	background-color: $footer-bg;
	background-image: url('../../public/assets/pattern.png');
	background-repeat: repeat;
	background-size: 20%;
	color: rgba(white, 50%);

	&-holder {
		padding: 2rem 0;
	}

	.nav-soc {
		border-top: 1px solid rgba($primary, 40%);
		margin-top: 2rem;
		padding-top: 2rem;

		.nav-link {
			.fb-icon,
			.youtube-icon,
			.inst-icon {
				fill: $primary;
			}

			&:hover {
				.fb-icon,
				.youtube-icon,
				.inst-icon {
					fill: rgba($primary, 50%);
				}
			}
		}
	}
}

body {
	&:not(.home) {
		.navbar--ds {
			//position: absolute;
			width: 100%;
		}

		main.main {
		}
	}

	.navbar--ds {
		.navbar-toggler-icon {
			border-color: rgb(255, 102, 203);
		}
	}

	&.vykorystannya-fpv-v-boyovykh-umovakh,
	&.vykorystannya-tsyvilnykh-droniv-v-boyovykh-umovakh,
	&.fpv-inzhenernyy-kurs,
	&.nichnyy-bomber-shulika {
		.navbar--ds {
			position: absolute;
			background-color: transparent;
			z-index: 2;
			.navbar-collapse {
				@include media-breakpoint-down(sm) {
					background-color: black;
				}
				border-radius: 10px;
			}
			&-lang {
				@include media-breakpoint-down(sm) {
					padding-left: 25px;
				}
			}
		}

		.container-hero {
			background-repeat: no-repeat;
			background-size: cover;
			padding-top: 180px;
			padding-bottom: 80px;
			min-height: calc(100vh - 140px);

			> * {
				position: relative;
				z-index: 1;
			}

			&-instr {
				h3 {
					color: white;
				}

				&-holder {
					h3 + {
						img {
							margin-left: 0;
						}
					}
					img {
						padding: 0;
						margin: 0 0 0 -30px;
						border: 6px solid black;
						@include media-breakpoint-down(sm) {
							width: 80px;
							height: 80px;
						}

						&:first-child {
							//margin-left: 0;
						}
					}
				}
			}

			&-holder {
				max-width: 900px;
				margin-left: auto;
				margin-right: auto;
				@include media-breakpoint-down(sm) {
					padding-left: 2rem;
					padding-right: 2rem;
				}

				&-line {
					color: white;
					//background-color: rgba(white,80%);
					//border-radius: 20px;
					margin-top: 40px;
					font-size: 28px;

					svg {
						width: 60px;
						fill: white;
					}
				}
			}

			h1 {
				color: white;
				@include media-breakpoint-down(sm) {
					padding-top: 2rem;
					@include media-breakpoint-down(sm) {
						font-size: 42px;
					}
					font-size: 52px;
				}
				font-size: 52px;
				text-shadow: 0 2px 20px rgba(black, 50%);
			}

			.vykorystannya-fpv-v-boyovykh-umovakh & {
				&:after {
					background-image: none;
				}
			}
		}
	}

	&.fpv-inzhenernyy-kurs,
	&.nichnyy-bomber-shulika,
	&.vykorystannya-tsyvilnykh-droniv-v-boyovykh-umovakh {
		.container-hero {
			background-position: center;

			&:after {
				content: '';
				position: absolute;
				background-image: linear-gradient(to top, rgba($black, 30%), rgba($black, 70%));
				width: 100%;
				min-height: calc(100vh - 140px);
				max-height: calc(100vh - 140px);
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

	&.vykorystannya-fpv-v-boyovykh-umovakh .container-hero {
		background-image: url('../../public/assets/ds-serv-1b.jpg');
	}

	&.vykorystannya-tsyvilnykh-droniv-v-boyovykh-umovakh .container-hero {
		background-image: url('../../public/assets/ds-serv-2b.jpg');
	}

	&.fpv-inzhenernyy-kurs .container-hero {
		background-image: url('../../public/assets/ds-serv-3b.jpg');
	}

	&.nichnyy-bomber-shulika .container-hero {
		background-image: url('../../public/assets/ds-serv-4b.jpg');
	}
}

.container-inner {
	background-color: rgba(white, 50%);
	padding: 2rem;
	border-radius: 20px;
	font-size: 18px;

	&-info {
		padding-top: 3rem;

		&-title {
			font-size: 32px;
			font-weight: 700;
			margin-bottom: 2rem;

			&:before {
				content: '';
				border-left: 8px solid lighten($brand, 10%);
				padding-left: 10px;
			}
		}
	}
}

.summary-box {
	padding-top: 2rem;
	@include media-breakpoint-up(sm) {
		margin-top: -150px;
	}

	&-holder {
		padding: 20px 25px;
		background-color: white;
		border-radius: 20px;
		box-shadow: 0 5px 15px rgba(black, 40%);

		h3 {
			font-size: 24px;
			padding-bottom: 10px;
			padding-top: 10px;
			font-weight: 700;
		}

		&-list {
			font-size: 18px;
			list-style: none;

			svg {
				width: 25px;
				fill: lighten($brand, 10%);
				margin-right: 5px;
				margin-left: -30px;
			}

			li {
				margin-bottom: 15px;
				line-height: 0.8;
			}
		}

		&-price {
			font-size: 32px;
			padding-bottom: 1.5rem;
			text-align: center;
			.nav-link {
				font-size: 14px;
				font-weight: 700;
			}
			.tab-content {
				margin-top: 1.3rem;
			}
		}

		&-btn {
			text-align: center;
		}
	}
}

.steps {
	display: flex;
	flex-direction: column;
	padding: 2rem 0 5rem;
	@include media-breakpoint-down(sm) {
		margin-left: -25px;
		margin-right: -25px;
	}
	.step {
		position: relative;
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		padding-top: 20px;
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
		&:before,
		&:after {
			position: absolute;
			width: 2px;
			height: 50%;
			content: '';
			left: 50px;
			@include media-breakpoint-down(sm) {
				left: 30px;
			}
			background-color: lighten(gray, 10%);
		}
		&:before {
			top: 0;
		}
		&:after {
			top: 50%;
		}
		&:not(:last-child)::after {
			background-color: lighten(gray, 10%);
		}
		&-number {
			position: relative;
			z-index: 2;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 100px;
			@include media-breakpoint-down(sm) {
				width: 60px;
				height: 60px;
			}
			border-radius: 100px;
			color: gray;
			background-color: lighten(lightgray, 8%);
			font-size: 22px;
			font-weight: 800;
			margin-right: 20px;
			&-inner {
				background-color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 60px;
				height: 60px;
				@include media-breakpoint-down(sm) {
					width: 40px;
					height: 40px;
				}
				border-radius: 100px;
				box-shadow: 0 2px 10px rgba(black, 10%);
			}
		}
		&-body {
			h3 {
			}
			P {
				padding: 0;
				margin: 0;
			}
		}
	}
}
.gallery {
	grid-gap: 0.5rem;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(6, 5vw);
	&__img {
		display: block;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
	&__item {
		&.gallery__item--1 {
			grid-column-end: 6;
			grid-column-start: 1;
			grid-row-end: 4;
			grid-row-start: 1;
		}
		&.gallery__item--2 {
			grid-column-end: 9;
			grid-column-start: 6;
			grid-row-end: 4;
			grid-row-start: 1;
		}
		&.gallery__item--3 {
			grid-column-end: 13;
			grid-column-start: 9;
			grid-row-end: 4;
			grid-row-start: 1;
		}
		&.gallery__item--4 {
			grid-column-end: 4;
			grid-column-start: 1;
			grid-row-end: 7;
			grid-row-start: 4;
		}
		&.gallery__item--5 {
			grid-column-end: 9;
			grid-column-start: 4;
			grid-row-end: 7;
			grid-row-start: 4;
		}
		&.gallery__item--6 {
			grid-column-end: 13;
			grid-column-start: 9;
			grid-row-end: 7;
			grid-row-start: 4;
		}
	}
}
.about-hero {
	max-width: 800px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.about-benefits {
	padding: 5rem 0;
	h2 {
		margin-bottom: 5rem;
		font-size: 52px;
	}
	&-list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		font-size: 24px;
		counter-reset: my-counter;
		li {
			padding: 2rem;
			max-width: 400px;
			position: relative;
			margin-bottom: 3rem;
			&:before {
				counter-increment: my-counter;
				content: '[0' counter(my-counter) ']';
				position: absolute;
				left: -30px;
				top: -60px;
				color: lightgray;
				font-size: 102px;
				z-index: -1;
				font-weight: 600;
				letter-spacing: -3px;
			}
		}
	}
}
.about-instructors {
	padding: 5rem 0;
	background-color: $brand;
	h2 {
		color: white;
		margin-bottom: 5rem;
		font-size: 52px;
	}
	.card {
		border-color: $brand;
	}
}
.contact-hero {
	background-color: #b2b2b2;
	background-image: url('../../public/assets/hero_ds2.jpg');
	background-repeat: no-repeat;
	background-position: center 2rem;
	padding: 15rem 0;
	background-size: contain;
	h1 {
		small {
			color: black;
			font-size: 42px;
			background-color: rgba(#b2b2b2, 80%);
			padding: 1rem 2rem;
		}
	}
}
.contact-content,
.donation-content {
	padding: 5rem 0;
	.lead {
		padding: 2rem;
		border: 1px solid lightgray;
		margin-right: auto;
		max-width: 500px;
		margin-left: auto;
		border-radius: 10px;
		margin-top: 2rem;
	}
}
.donation-hero {
	background-color: #b2b2b2;
	padding: 5rem 0;
}
.navbar--ds-lang {
	display: flex;
	align-items: center;
	&-btns {
		display: flex;
		margin-right: 15px;
		.btn-ds-lang {
			margin-left: 15px;
			color: white;
			cursor: pointer;
			transition: color 0.15s ease;
			&.active {
				color: $primary;
			}
			&:hover {
				color: $primary;
			}
		}
	}
	svg {
		width: 25px;
		height: 25px;
		fill: white;
	}
}